const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.rossing.forwoodsafety.com',
    USER_TOKEN_URL: 'https://jglat4kar7.execute-api.us-east-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://9wb2igm6pb.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'teams-container-1.10.2',
    HOSTNAME: 'https://teams.staging.rossing.forwoodsafety.com',
    TEAMS_MICROFRONTEND_URL: 'https://teams-service.staging.rossing.forwoodsafety.com',
    TARGETS_MICROFRONTEND_URL: 'https://targets.staging.rossing.forwoodsafety.com',
    TEAMS_ACCESS_ROLE: 'TeamsAccess',
    TEAM_TARGETS_ACCESS_ROLE: 'TeamTargetsAccess',
  },
  configurationService: {
    URL: 'https://config.staging.rossing.forwoodsafety.com',
    WEBSOCKET: 'wss://ywkefmoqw1.execute-api.us-east-1.amazonaws.com/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
